import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Login/Register')
  },
  {
    path: '/',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home/Home')
      },
      {
        path: '/order_sell',
        name: 'order_sell',
        component: () => import('@/views/Order/OrderSell')
      },
      {
        path: '/order_buy',
        name: 'order_buy',
        component: () => import('@/views/Order/OrderBuy')
      },
      {
        path: '/trading_hall',
        name: 'trading_hall',
        component: () => import('@/views/TradingHall/TradingHall')
      },
      {
        path: '/add_product',
        name: 'add_product',
        component: () => import('@/views/Product/AddProduct')
      },
      {
        path: '/product_list',
        name: 'product_list',
        component: () => import('@/views/Product/ProductList')
      },
      {
        path: '/address_manage',
        name: 'address_manage',
        component: () => import('@/views/Account/Address')
      },
      {
        path: '/personal_information',
        name: 'personal_information',
        component: () => import('@/views/Account/Person')
      },
      {
        path: '/pay_account_manage',
        name: 'pay_account_manage',
        component: () => import('@/views/Account/PayAccount')
      },
      {
        path: '/iframe_page',
        name: 'iframe_page',
        component: () => import('@/views/Iframe/index')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history', //配置这个以后，路径就没有#了
  routes
})

export default router
