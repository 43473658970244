import request from '@/utils/request'

const product = {
  uploadCoverImg(params) {
    return request.post('/api/product/upload_img', params)
  },
  publishProduct(params) {
    return request.post('/api/product/publish_product', params)
  },
  getProductList(params) {
    return request.post('/api/product/product_list', params)
  },
  getProductDetail(params) {
    return request.post('/api/product/get_product_detail', params)
  },
  downProduct(params) {
    return request.post('/api/product/down_shelves', params)
  },
  tradingHallGetProductList(params) {
    return request.post('/api/product/search_product', params)
  }
}

export default product
