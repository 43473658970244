import request from '@/utils/request'

const user_profile = {
  getRouter() {
    return request.get('/api/router/router_list/')
  },
  getLoginUserInfo(params) {
    return request.post('/api/user/get_login_user_info', params)
  },
  updateUser(params) {
    return request.post('/api/user/update_login_user_info', params)
  },
  upload_head_img(params) {
    return request.post('/api/user/upload_head_img', params)
  },
  register(params) {
    return request.post('/api/user/register', params)
  },
  login(params) {
    return request.post('/api/user/login', params)
  },
  getDate(params) {
    return request.post('/api/user/get_date', params)
  },
  getKaptcha() {
    return request.get('/api/user/get_kaptcha', { responseType: 'arraybuffer' })
  }
}

export default user_profile
