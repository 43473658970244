import request from '@/utils/request'

const address = {
  getAddressList(params) {
    return request.post('/api/address/get_address_list', params)
  },
  addAddress(params) {
    return request.post('/api/address/add_address', params)
  },
  deleteAddress(params) {
    return request.post('/api/address/delete_address', params)
  },
  getAddressById(params) {
    return request.post('/api/address/get_address_by_id', params)
  },
  editAddress(params) {
    return request.post('/api/address/edit_address', params)
  }
}

export default address
