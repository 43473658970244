// import Cookie from 'js-cookie'
// import VueSession from 'vue-session'

export default {
  state: {
    isCollapse: false,
    currentMenu: null,
    menu: [],
    tabsList: [
      {
        path: '/',
        name: 'home',
        label: '首页',
        icon: 's-home'
      }
    ]
  },
  mutations: {
    selectMenu(state, val) {
      if (val.name !== 'home') {
        state.currentMenu = val
        let result = state.tabsList.findIndex(item => item.name === val.name)
        result === -1 ? state.tabsList.push(val) : ''
        sessionStorage.setItem('tagList', JSON.stringify(state.tabsList))
        // Cookie.set('tagList', JSON.stringify(state.tabsList))
      } else {
        state.currentMenu = null
      }
    },
    getMenu(state) {
      if (sessionStorage.getItem('tagList')) {
        let tagList = JSON.parse(sessionStorage.getItem('tagList'))
        state.tabsList = tagList
      }
    },
    closeTab(state, val) {
      let result = state.tabsList.findIndex(item => item.name === val.name)
      state.tabsList.splice(result, 1)
      sessionStorage.setItem('tagList', JSON.stringify(state.tabsList))
      // Cookie.set('tagList', JSON.stringify(state.tabsList))
    },
    collapseMenu(state) {
      state.isCollapse = !state.isCollapse
    }
  },
  actions: {}
}
