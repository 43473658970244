import request from '@/utils/request'

const account = {
  getWalletInfo(params) {
    return request.post('/api/wallet/get_wallet_info', params)
  },
  topUp(params) {
    return request.post('/api/wallet/top_up', params)
  },
  openWallet(params) {
    return request.post('/api/wallet/open_wallet', params)
  },
  closeWallet(params) {
    return request.post('/api/wallet/close_wallet', params)
  },
  deductions(params) {
    return request.post('/api/wallet/deductions', params)
  }
}

export default account
