// import Cookies from 'js-cookie'

const TokenKey = 'token'
const Username = 'username'
const UserId = 'userid'
const UserNickName = 'nick'
const TabList = 'tagList'

export function getToken() {
  // return Cookies.get(TokenKey)
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  // return Cookies.set(TokenKey, token)
  return sessionStorage.setItem(TokenKey, token)
}

export function getUserNickName() {
  // return Cookies.get(UserNickName)
  return sessionStorage.getItem(UserNickName)
}

export function setUserNickName() {
  // return Cookies.set(UserNickName)
  return sessionStorage.setItem(UserNickName)
}

export function removeToken() {
  // return Cookies.remove(TokenKey)
  return sessionStorage.removeItem(TokenKey)
}

export function removeTablist() {
  // return Cookies.remove(TabList)
  return sessionStorage.removeItem(TabList)
}

export function setUsername(username) {
  // return Cookies.set(Username, username)
  return sessionStorage.setItem(Username, username)
}

export function setUserId(userid) {
  // return Cookies.set(UserId, userid)
  return sessionStorage.setItem(UserId, userid)
}
