import request from '@/utils/request'

const order = {
  createOrder(params) {
    return request.post('/api/order/create_order', params)
  },
  getOrderInfo(params) {
    return request.post('/api/order/get_order', params)
  },
  pay(params) {
    return request.post('/api/order/pay', params)
  },
  confirmGood(params) {
    return request.post('/api/order/confirm', params)
  },
  evaluationSeller(params) {
    return request.post('/api/order/evaluation', params)
  },
  deliverGoods(params) {
    return request.post('/api/order/deliver_goods', params)
  },
  getOrderStatusCount(params) {
    return request.post('/api/order/get_order_status_count', params)
  }
}

export default order
