import user from './user'
import address from './address'
import product from '@/api/product'
import order from '@/api/order'
import account from '@/api/account'

export default {
  user,
  address,
  product,
  order,
  account
}
