import router from './router'
import { getToken, removeToken } from '@/utils/auth'

router.beforeEach((to, from, next) => {
  let token = getToken()
  if (to.path === '/login') {
    removeToken()
  }
  if (token === 'undefined') {
    token = ''
  }
  if (!token && to.path === '/register') {
    next()
  } else if (!token && to.path !== '/login') {
    console.log(to.path)
    next({ path: '/login', query: { url: to.path } })
  } else {
    next()
  }
})
